import { useTranslation } from 'react-i18next';

export const Navigation = (props) => {
  const { t } = useTranslation();

  return (
    <nav id='menu' className='navbar navbar-default navbar-fixed-top' style={{
      backgroundColor: '#171e24'
    }}>
      <div className='container'>
        <div className='navbar-header'>
          <button
            type='button'
            className='navbar-toggle collapsed'
            data-toggle='collapse'
            data-target='#bs-example-navbar-collapse-1'
          >
            {' '}
            <span className='sr-only'>Toggle navigation</span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
            <span className='icon-bar'></span>{' '}
          </button>
          <a href="#header"><img src="img/proto_logo.png" width="50" height="50" /></a>
        </div>

        <div
          className='collapse navbar-collapse'
          id='bs-example-navbar-collapse-1'
        >
          <ul className='nav navbar-nav navbar-right'>
            <li>
              <a href='#features' className='page-scroll'>
              {t('Navigation.header1')}
              </a>
            </li>
            <li>
              <a href='#portfolio' className='page-scroll'>
              {t('Navigation.header2')}
              </a>
            </li>
            <li>
              <a  href='https://www.protosimulator.com/privacy-policy.html' target="_blank" className='page-scroll'>
              {t('Navigation.header3')}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  )
}
